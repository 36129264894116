import _loader from "./js-yaml/loader";
import _dumper from "./js-yaml/dumper";
import _type from "./js-yaml/type";
import _schema from "./js-yaml/schema";
import _failsafe from "./js-yaml/schema/failsafe";
import _json from "./js-yaml/schema/json";
import _core from "./js-yaml/schema/core";
import _default_safe from "./js-yaml/schema/default_safe";
import _default_full from "./js-yaml/schema/default_full";
import _exception from "./js-yaml/exception";
var exports = {};
var loader = _loader;
var dumper = _dumper;

function deprecated(name) {
  return function () {
    throw new Error("Function " + name + " is deprecated and cannot be used.");
  };
}

exports.Type = _type;
exports.Schema = _schema;
exports.FAILSAFE_SCHEMA = _failsafe;
exports.JSON_SCHEMA = _json;
exports.CORE_SCHEMA = _core;
exports.DEFAULT_SAFE_SCHEMA = _default_safe;
exports.DEFAULT_FULL_SCHEMA = _default_full;
exports.load = loader.load;
exports.loadAll = loader.loadAll;
exports.safeLoad = loader.safeLoad;
exports.safeLoadAll = loader.safeLoadAll;
exports.dump = dumper.dump;
exports.safeDump = dumper.safeDump;
exports.YAMLException = _exception; // Deprecated schema names from JS-YAML 2.0.x

exports.MINIMAL_SCHEMA = _failsafe;
exports.SAFE_SCHEMA = _default_safe;
exports.DEFAULT_SCHEMA = _default_full; // Deprecated functions from JS-YAML 1.x.x

exports.scan = deprecated("scan");
exports.parse = deprecated("parse");
exports.compose = deprecated("compose");
exports.addConstructor = deprecated("addConstructor");
export default exports;